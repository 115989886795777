import React from 'react';
import { trabajos } from '../data/trabajos.js';
import { Link } from 'react-router-dom';

export const ListadoTrabajoComponent = ({ limite }) => {

  return (
    <>
      <section className='works'>
        {
          trabajos.slice(0, limite).map(trabajo => {
            return (
              <article key={trabajo.id} className='work-item'>
                <div className='mask'>
                  {/* Utiliza Link para redirigir al enlace deseado */}
                  <Link to={`/proyecto/${trabajo.id}`}>
                    <img src={"/imagenes/" + trabajo.id + ".png"} alt="" />
                  </Link>
                </div>
                <span>{trabajo.categorias}</span>
                <h2><Link to={`/proyecto/${trabajo.id}`}>{trabajo.nombre}</Link></h2>
                <h3>{trabajo.tecnologias}</h3>
                <hr />
              </article>
            )
          })
        }
      </section>
    </>
  )
}