import './App.css';
import { RutasComponent } from './Router/RutasComponent';

function App() {
  return (
    <div className="layout">

        <RutasComponent/>
    </div>
  );
}

export default App;
