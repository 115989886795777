import React from 'react'

export const CurriculumComponent = () => {
  return (
    <div className='page'>
        <h1 className='heading'>Curriculum</h1>

      <h2>Experiencia</h2>
      <ul>
        <li></li>
      </ul>
      <h2>Conocimientos</h2>
      <h2>Educación</h2>
      <h2>Proyectos personales</h2>
      <h2>Reconocimientos y premios</h2>
      <h2>Otros</h2>

    </div>
  )
}
