import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { ListadoTrabajoComponent } from './ListadoTrabajoComponent'

export const InicioComponent = () => {
  return (
    <div className='home'>
      <h1>
       Soy <strong>Michael Cuadros</strong>, estudiante de noveno ciclo de Ingeniería de Sistemas de Información en la Universidad Peruana de Ciencias Aplicadas (UPC).
       Destacando académicamente perteneciendo al décimo superior. 
       Mi trayectoria académica es una clara evidencia de mi compromiso inquebrantable con el crecimiento personal y la innovación constante.
      </h1>

      <h2 className='title'>
      Estoy abierto a desafíos que puedan expandir mi horizonte y proporcionarme la oportunidad de aplicar y cultivar mis habilidades en un entorno laboral. Si tienes un proyecto no dudes en contactarte conmigo, estaré gustoso de poder apoyarte. 

        <br/>
        <br/>
        <Link className="contact_buton" to="/contacto">Contacta conmigo</Link> 
      </h2>

      <hr/>
      <section className='lasts-works'>
      <h2 className='heading'>Algunos de mis proyectos</h2>
      <h3>Para obtener una visión más completa de mi trabajo, te invito a explorar mi <NavLink to={"/portafolio"}>portafolio</NavLink> </h3>

        <div className='InicioList'>
        <ListadoTrabajoComponent limite={2}/>
        </div>
      </section>

    </div>
  )
}
