import React from 'react'

export const ServiciosComponent = () => {
    return (
        <div className='page'>
            <h1 className='heading'>Nuestros Servicios</h1>

            <section className='services'>
                <article className='service'>
                    <h2>Desarrollo Frontend con React</h2>
                    <p>Desarrollamos interfaces interactivas y dinámicas utilizando React, la biblioteca de JavaScript de última generación. Tu sitio web cobrará vida con componentes reutilizables y un rendimiento excepcional.</p>
                </article>

                <article className='service'>
                    <h2>Desarrollo Backend</h2>
                    <p>Construimos la columna vertebral de tu aplicación utilizando tecnologías avanzadas como Express, Node.js, MongoDB y Mongoose. Tu aplicación estará en manos seguras y eficientes.</p>
                </article>

                <article className='service'>
                    <h2>Seguridad con JWT</h2>
                    <p>Implementamos autenticación segura utilizando tokens JWT (JSON Web Tokens). Tus datos estarán protegidos y solo accesibles para los usuarios autorizados, manteniendo la confidencialidad.</p>
                </article>
            </section>
        </div>
    )
}
