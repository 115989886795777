import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {trabajos} from '../data/trabajos.js'
export const ProyectoComponent = () => {
    const params=useParams();
    const [proyecto_mostrar,setProyecto_mostrar]=useState({});

    useEffect(()=>{
        let proyecto=trabajos.filter(trabajo=> trabajo.id===params.id);
        setProyecto_mostrar(proyecto[0]); //si le paso proyecto le estare enviando un array, le tengo que poner proyecto[0] para mandarle lo unico que hay dentro
    },[])


  return (
    <div className='page page-work'>
        <h1 className='heading'>{proyecto_mostrar.nombre}</h1>
        <p>{proyecto_mostrar.tecnologias}</p>
         <p>{proyecto_mostrar.descripcion}</p>

         <a href={proyecto_mostrar.url} target='_blank'>Visitar la Pagina</a>{/**target=_'blank' es para q se abra una neuva pagina del link */}
        <div className='mask'>
                <img src={"/imagenes/"+proyecto_mostrar.id+".png"} alt=""/>
         </div>

    </div>
  )
}
