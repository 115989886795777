import React from 'react';
import {Routes,Route,BrowserRouter,Navigate} from "react-router-dom";
import { InicioComponent } from '../components/InicioComponent';
import { PortafolioComponent } from '../components/PortafolioComponent';
import { ServiciosComponent } from '../components/ServiciosComponent';
import { CurriculumComponent } from '../components/CurriculumComponent';
import { ContactoComponent } from '../components/ContactoComponent';
import { HeaderNav } from '../components/layout/HeaderNav';
import { Footer } from '../components/layout/Footer';
import { ProyectoComponent } from '../components/ProyectoComponent';

export const RutasComponent = () => {
  return (
    <BrowserRouter>
        {/**Header y NAVEGACION */}
        <HeaderNav/>

        {/**Contenido central */}
        <section className='content'>
        <Routes>
            <Route path="/" element={<Navigate to="/inicio"/>}/> {/**para que me lleve a inicio al ingresar al link */}
            <Route path="/inicio" element={<InicioComponent/>}/>
            <Route path="portafolio" element={<PortafolioComponent/>}/>
            <Route path="/servicios" element={<ServiciosComponent/>}/>
            <Route path="/curriculum" element={<CurriculumComponent/>}/>
            <Route path="/contacto" element={<ContactoComponent/>}/>
            <Route path="/proyecto/:id" element={<ProyectoComponent/>}/>
            <Route path="*" element={
                <div className='page'>
            <h1 className='heading'>Error 404</h1>
            </div>
            }/>
        </Routes>

        </section>

        {/**Pie de pagina */}
        
        <Footer/>
    
    </BrowserRouter>
  )
}
