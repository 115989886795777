import React from 'react';
import { ListadoTrabajoComponent } from './ListadoTrabajoComponent.js';

export const PortafolioComponent = () => {
  return (
    <div className='page'>
        <h1 className='heading'>Portafolio</h1>

        <ListadoTrabajoComponent/>

      </div>
  )
}
